var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "content_box mt10" }, [
        _c("div", { staticClass: "flex_box" }, [
          _c("span", { staticClass: "tot_num" }, [
            _vm._v(_vm._s(_vm.$t("msg.ONEX010T010.011"))),
            _vm._v(" : "),
            _c("span", { staticClass: "num" }, [
              _vm._v(_vm._s(_vm.totalCount)),
            ]),
          ]),
          _c("span", { staticClass: "input_box" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.txtFilter,
                  expression: "txtFilter",
                },
              ],
              staticClass: "wid300",
              attrs: {
                type: "text",
                id: "filter",
                autocomplete: "off",
                placeholder: _vm.$t("msg.ONEX010T010.012"),
              },
              domProps: { value: _vm.txtFilter },
              on: {
                keyup: function ($event) {
                  return _vm.changeFilter()
                },
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.txtFilter = $event.target.value
                },
              },
            }),
          ]),
          _vm.relYn
            ? _c("span", { staticClass: "input_box" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selPo,
                        expression: "selPo",
                      },
                    ],
                    staticClass: "wid100",
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.selPo = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  [
                    _c("option", { attrs: { value: "POL" } }, [
                      _vm._v("Departure"),
                    ]),
                    _c("option", { attrs: { value: "POD" } }, [
                      _vm._v("Arrival"),
                    ]),
                  ]
                ),
              ])
            : _vm._e(),
          _vm.relYn
            ? _c("span", { staticClass: "input_box" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selCtr,
                        expression: "selCtr",
                      },
                    ],
                    staticClass: "wid200",
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.selCtr = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        function ($event) {
                          return _vm.checkSelectCtr()
                        },
                      ],
                    },
                  },
                  [
                    _c("option", { attrs: { value: "" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.ONEX010T010.004"))),
                    ]),
                    _vm._l(_vm.ctrCdList, function (ctrInfo) {
                      return _c(
                        "option",
                        {
                          key: ctrInfo.ctrCd,
                          domProps: { value: ctrInfo.ctrCd },
                        },
                        [_vm._v(_vm._s(ctrInfo.ctrEnm))]
                      )
                    }),
                  ],
                  2
                ),
              ])
            : _vm._e(),
          _c("span", { staticClass: "ml_auto cal_btn" }, [
            _c(
              "a",
              {
                staticClass: "button sm",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.exlDownload()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("msg.MYIN020T010.016")))]
            ),
          ]),
        ]),
        _c("div", {
          style: `width: 100%; height: ${_vm.gridHeight}px`,
          attrs: { id: "realgrid" },
        }),
      ]),
      _c("div", { staticClass: "request_area" }, [
        _c(
          "div",
          {
            staticClass: "top",
            staticStyle: { cursor: "pointer" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                !_vm.reqPixYn ? _vm.openRequestArea() : ""
              },
            },
          },
          [
            _c("span", { staticClass: "tit" }, [
              _vm._v(_vm._s(_vm.$t("msg.ONEX010T010.013"))),
            ]),
            _c("span", { staticClass: "ntx" }, [
              _vm._v(_vm._s(_vm.$t("msg.ONEX010T010.014"))),
              _c("span", [
                _vm._v(
                  " (" +
                    _vm._s(
                      _vm.$t("msg.ONEX010T010.138", { cnt: _vm.checkedCount })
                    ) +
                    ")"
                ),
              ]),
            ]),
            _c(
              "button",
              {
                staticClass: "reqbtn up",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    !_vm.reqPixYn ? _vm.openRequestArea() : ""
                  },
                },
              },
              [_c("span", [_vm._v(_vm._s(_vm.$t("msg.ONEX010T010.015")))])]
            ),
            _c("button", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.reqPixYn,
                  expression: "reqPixYn",
                },
              ],
              staticClass: "pixbtn",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.pixRequestArea()
                },
              },
            }),
            _c("button", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.reqPixYn,
                  expression: "!reqPixYn",
                },
              ],
              staticClass: "noPixbtn",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.pixRequestArea()
                },
              },
            }),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "sub_area",
            staticStyle: { display: "block", cursor: "pointer" },
            attrs: { id: "working-progress-import-request-area" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                !_vm.reqPixYn ? _vm.closeRequestArea() : ""
              },
            },
          },
          [
            _c("div", { staticClass: "top" }, [
              _c("span", { staticClass: "tit" }, [
                _vm._v(_vm._s(_vm.$t("msg.ONEX010T010.013"))),
              ]),
              _c("span", { staticClass: "ntx" }, [
                _vm._v(_vm._s(_vm.$t("msg.ONEX010T010.014"))),
                _c("span", [
                  _vm._v(
                    " (" +
                      _vm._s(
                        _vm.$t("msg.ONEX010T010.138", { cnt: _vm.checkedCount })
                      ) +
                      ")"
                  ),
                ]),
              ]),
              _c(
                "button",
                {
                  staticClass: "reqbtn down",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      !_vm.reqPixYn ? _vm.closeRequestArea() : ""
                    },
                  },
                },
                [_c("span", [_vm._v(_vm._s(_vm.$t("msg.ONEX010T010.016")))])]
              ),
              _c("button", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.reqPixYn,
                    expression: "reqPixYn",
                  },
                ],
                staticClass: "pixbtn",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.pixRequestArea()
                  },
                },
              }),
              _c("button", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.reqPixYn,
                    expression: "!reqPixYn",
                  },
                ],
                staticClass: "noPixbtn",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.pixRequestArea()
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "list" }, [
              _c("div", { staticClass: "item" }, [
                _c("p", [_vm._v(_vm._s(_vm.$t("msg.ONEX010T010.023")))]),
                _c("ul", [
                  _c(
                    "li",
                    {
                      class:
                        _vm.requestAreaBtn.charge01 === "Y" ? "" : "disabled",
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "pop",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.fnRequestCharge("01")
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("msg.ONEX010T010.024")))]
                      ),
                    ]
                  ),
                  _c(
                    "li",
                    {
                      class:
                        _vm.requestAreaBtn.charge02 === "Y" ? "" : "disabled",
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "pop",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.fnRequestCharge("02")
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("msg.ONGO080G010.034")))]
                      ),
                    ]
                  ),
                ]),
              ]),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showDoUl(),
                      expression: "showDoUl()",
                    },
                  ],
                  staticClass: "item",
                },
                [
                  _c("p", [_vm._v(_vm._s(_vm.$t("msg.ONEX010T010.060")))]),
                  _c("ul", [
                    _c(
                      "li",
                      {
                        class:
                          _vm.requestAreaBtn.do01 === "Y" ? "" : "disabled",
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "pop",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.fnRequestDO("01")
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("msg.ONEX010T010.087")))]
                        ),
                      ]
                    ),
                    _vm.auth.userCtrCd === "KR"
                      ? _c(
                          "li",
                          {
                            class:
                              _vm.requestAreaBtn.do02 === "Y" ? "" : "disabled",
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "pop",
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.fnRequestDO("02")
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("msg.ONEX010T010.088")))]
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.auth.userCtrCd === "KR"
                      ? _c(
                          "li",
                          {
                            class:
                              _vm.requestAreaBtn.do03 === "Y" ? "" : "disabled",
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "link",
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.fnRequestDO("03")
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("msg.ONEX010T010.089")))]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ]
              ),
              _vm.memberDetail.userCtrCd === "KR"
                ? _c("div", { staticClass: "item" }, [
                    _c("p", [_vm._v(_vm._s(_vm.$t("msg.ONEX010T010.030")))]),
                    _c("ul", [
                      _c(
                        "li",
                        {
                          class:
                            _vm.requestAreaBtn.trans01 === "Y"
                              ? ""
                              : "disabled",
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "link",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.fnRequestTrans("01")
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("msg.ONEX010T010.090")))]
                          ),
                        ]
                      ),
                      _c(
                        "li",
                        {
                          class:
                            _vm.requestAreaBtn.trans02 === "Y"
                              ? ""
                              : "disabled",
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "pop",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.fnRequestTrans("02")
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("msg.ONEX010T010.091")))]
                          ),
                        ]
                      ),
                    ]),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "item case2" }, [
                _c("p", [_vm._v(_vm._s(_vm.$t("msg.ONEX010T010.037")))]),
                _c(
                  "ul",
                  [
                    _c(
                      "li",
                      {
                        class:
                          _vm.requestAreaBtn.report01 === "Y" ? "" : "disabled",
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "pop",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.fnRequestReport("01")
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("msg.ONEX010T010.040")))]
                        ),
                      ]
                    ),
                    _c(
                      "li",
                      {
                        class:
                          _vm.requestAreaBtn.report02 === "Y" ? "" : "disabled",
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "pop",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.fnRequestReport("02")
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("msg.ONEX010T010.044")))]
                        ),
                      ]
                    ),
                    _c(
                      "li",
                      {
                        class:
                          _vm.requestAreaBtn.report03 === "Y" ? "" : "disabled",
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "pop",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.fnRequestReport("03")
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("msg.ONEX010T010.039")))]
                        ),
                      ]
                    ),
                    _vm.memberDetail.userCtrCd !== "CN"
                      ? [
                          _c(
                            "li",
                            {
                              class:
                                _vm.requestAreaBtn.report04 === "Y"
                                  ? ""
                                  : "disabled",
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "pop",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.fnRequestReport("04")
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("msg.ONEX010T010.059")))]
                              ),
                            ]
                          ),
                        ]
                      : _vm._e(),
                    _vm.memberDetail.userCtrCd === "KR"
                      ? [
                          _c(
                            "li",
                            {
                              class:
                                _vm.requestAreaBtn.report05 === "Y"
                                  ? ""
                                  : "disabled",
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "pop",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.fnRequestReport("05")
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("msg.ONEX010T010.080")))]
                              ),
                            ]
                          ),
                        ]
                      : _vm._e(),
                    _c(
                      "li",
                      {
                        class:
                          _vm.requestAreaBtn.report06 === "Y" ? "" : "disabled",
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "pop",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.fnRequestReport("06")
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("msg.ONEX010T010.060")))]
                        ),
                      ]
                    ),
                    _c(
                      "li",
                      {
                        class:
                          _vm.requestAreaBtn.report07 === "Y" ? "" : "disabled",
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "pop",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.fnRequestReport("07")
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("msg.ONEX010T010.041")))]
                        ),
                      ]
                    ),
                    _vm.memberDetail.userCtrCd === "ID"
                      ? _c(
                          "li",
                          {
                            class:
                              _vm.requestAreaBtn.report08 === "Y"
                                ? ""
                                : "disabled",
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "pop",
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.fnRequestReport("08")
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("msg.ONEX010T010.092")))]
                            ),
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "li",
                      {
                        class:
                          _vm.requestAreaBtn.report09 === "Y" ? "" : "disabled",
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "pop",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.fnRequestReport("09")
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("msg.ONEX010T010.159")))]
                        ),
                      ]
                    ),
                    _vm.memberDetail.userCtrCd === "KR"
                      ? _c(
                          "li",
                          {
                            class:
                              _vm.requestAreaBtn.report10 === "Y"
                                ? ""
                                : "disabled",
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "pop",
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.fnRequestReport("10")
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("msg.ONEX010T010.161")))]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
            ]),
          ]
        ),
      ]),
      _c(
        "win-layer-pop",
        { staticClass: "sample_popup_list" },
        [
          _vm.customComponent
            ? _c(_vm.customComponent, {
                tag: "component",
                attrs: { "parent-info": _vm.infoData },
                on: { close: _vm.closePopup },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "win-layer-pop",
        { staticClass: "import_ongoing_list_popup_tp2" },
        [
          _vm.popupTp2Component
            ? _c(_vm.popupTp2Component, {
                tag: "component",
                attrs: {
                  "ok-hidden": _vm.isHidden,
                  "is-show-pop": _vm.isShowPop,
                  detail: _vm.popupTp2Params,
                },
                on: { close: _vm.closeTp2Popup },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "win-layer-pop",
        { staticClass: "import_ongoing_list_popup_tp3" },
        [
          _vm.popupTp3Component
            ? _c(_vm.popupTp3Component, {
                tag: "component",
                attrs: { "parent-info": _vm.popupTp3Params },
                on: {
                  close: _vm.closeTp3Popup,
                  callback: _vm.popupTp3Callback,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("common-unsolved", {
        ref: "commonUnsolved",
        on: { callback: _vm.searchAct },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }